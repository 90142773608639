define("@additive-apps/ui/validators/editor-links", ["exports", "ember-validators"], function (_exports, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateLink = validateLink;
  /**
   * Validates the link with a given set of allowed values or a given format
   *
   * @function validateLink
   *
   * @param {Array} allowedValues
   * @param {Object} format
   *
   * @return {Boolean | String}
   */
  function validateLink(format) {
    let allowedValues = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    return (key, newValue) => {
      newValue = newValue.trim();
      let isAllowedValue = false;
      if (Array.isArray(allowedValues)) {
        allowedValues.some(value => {
          // wrap allowed value in placeholder braces, because values are given without them
          const allowedValue = `{#${value.trim()}#}`;
          // compare placeholder but allow spaces between delimiter
          if (allowedValue.slice(0, 2) == newValue.slice(0, 2) && allowedValue.slice(-2) == newValue.slice(-2)) {
            if (allowedValue.slice(2, -2) === newValue.slice(2, -2).trim()) {
              isAllowedValue = true;
              return true;
            }
          }
        });
      }
      if (isAllowedValue || !format) {
        return true;
      }
      return (0, _emberValidators.validate)('format', newValue, format, null, key);
    };
  }
});