define("@additive-apps/ui/utils/chart-plugins", ["exports", "chart.js/helpers", "date-fns", "date-fns/locale"], function (_exports, ChartHelpers, _dateFns, _locale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chartReferencePlugin = _exports.chartFillEmptyDataPlugin = _exports.chartEmphasizeCurrentWeekPlugin = void 0;
  /**
   * The reference dataset is expected to have the `isReference` property
   * This dataset will be drawn differently in the chart and the tooltip
   *
   * @constant chartReferencePlugin
   * @type {Object}
   */
  const chartReferencePlugin = _exports.chartReferencePlugin = {
    afterDatasetsUpdate: chart => {
      const labelsCount = chart.data.labels.length;
      const chartWidth = chart.chartArea.right - chart.chartArea.left;
      const chartWidthPerLabel = chartWidth / labelsCount;
      chart.data.datasets.forEach((dataset, datasetIndex) => {
        if (dataset.isReference) {
          ChartHelpers.each(chart.getDatasetMeta(datasetIndex).data, function (bar, index) {
            // set width of bar to the width of a label on the x-axis
            bar.width = chartWidthPerLabel + 1;
            // position the bar to the beginning of a label on the x-axis
            bar.x = chart.chartArea.left + index * chartWidthPerLabel + chartWidthPerLabel / 2;
          });
        }
      });
    }
  };

  /**
   * If we have a weekly occupancy report this plugin emphasizes
   * the label of the week on the chart in which we are currently.
   *
   * @constant chartEmphasizeCurrentWeekPlugin
   * @type {Object}
   */
  const chartEmphasizeCurrentWeekPlugin = _exports.chartEmphasizeCurrentWeekPlugin = {
    beforeInit: chart => {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let year = today.getFullYear();
      let isMonth = false;
      let xFontColors = [];
      const dateLocales = {
        de: _locale.de,
        en: _locale.en
      };
      const currentLocale = chart.config.options.locale;

      // Try whether the label can be splitted and parsed to a Date object
      try {
        chart.data.labels.forEach(label => {
          let currWeek = label.replace(' ', '').split('-');
          let [day, month] = currWeek[0].split('.');
          let start = new Date(`${year}-${month}-${day}`);
          start.setHours(0, 0, 0, 0);
          [day, month] = currWeek[1].split('.');
          let end = new Date(`${year}-${month}-${day}`);
          end.setHours(0, 0, 0, 0);
          if (today >= start && today <= end) {
            xFontColors.push('#00172F');
          } else {
            xFontColors.push('#8f9ea4');
          }
        });
      } catch (err) {
        chart.data.labels.forEach(label => {
          if (label === (0, _dateFns.format)(today, 'MMMM', {
            locale: dateLocales[currentLocale]
          }) || label === (0, _dateFns.format)(today, 'MMM', {
            locale: dateLocales[currentLocale]
          }) || label === (0, _dateFns.format)(today, 'MMM.', {
            locale: dateLocales[currentLocale]
          })) {
            isMonth = true;
            xFontColors.push('#00172F');
          } else {
            // if the label is not a date the color is set to default
            xFontColors.push('#8f9ea4');
          }
        });

        /*
         * if there's no matching month-label for current month all labels
         * will receive the same default color
         */
        if (!isMonth) {
          xFontColors = ['#666'];
        }
      }
      // array of all the colors in the same order of the labels they refer to
      chart.config.options.scales.x.ticks['color'] = xFontColors;
    }
  };

  /**
   * Fills the data array with zeroes if the dataset only defines the tooltipData
   *
   * @constant chartFillEmptyDataPlugin
   * @type {Object}
   */
  const chartFillEmptyDataPlugin = _exports.chartFillEmptyDataPlugin = {
    beforeUpdate(chart) {
      chart.data.datasets.forEach(dataset => {
        if (dataset.tooltipData && !dataset.data) {
          dataset.data = dataset.tooltipData.map(() => 0);
        }
      });
    }
  };
});