define("@additive-apps/ui/utils/query-param-util", ["exports", "@additive-apps/ui/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeQueryParamValue = _exports.getQueryParams = void 0;
  /**
   * Normalizes url values.
   * - Array: in url we use comma seperated values for arrays
   *    `value1,value2` -> `['value1', 'value2']`
   *
   * @function normalizeQueryParamValue
   * @param {Any} input
   */
  const normalizeQueryParamValue = input => {
    if (typeof input === 'string') {
      if (input.indexOf(_constants.URL_ARRAY_DELIMITER) >= 0) {
        return input.split(_constants.URL_ARRAY_DELIMITER);
      }
    }
    return input;
  };

  /**
   * takes an object and transforms it into queryParams
   *
   * @function getQueryParams
   * @param {String} qps
   */
  _exports.normalizeQueryParamValue = normalizeQueryParamValue;
  const getQueryParams = qps => {
    const queryParams = new URLSearchParams(qps);
    const stringedQueryParams = queryParams.toString();
    return stringedQueryParams !== '' && `?${stringedQueryParams}`;
  };
  _exports.getQueryParams = getQueryParams;
});