define("@additive-apps/ui/components/ui-dd-sort/item", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-dd-sort/item", "@ember/runloop", "drag-drop-touch"], function (_exports, _component, _item, _runloop, _dragDropTouch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  /**
   * wraps the items that can be dragged in the dd-sort component
   *
   * @class ui-dd-sort/item
   */
  //eslint-disable-next-line ember/no-classic-classes,ember/require-tagless-components
  var _default = _exports.default = _component.default.extend({
    layout: _item.default,
    attributeBindings: ['draggable', 'index'],
    classNames: ['ui-dd-sort__item'],
    /**
     * whether the item is draggable
     *
     * @property draggable
     * @type {Boolean}
     * @default true
     */
    draggable: true,
    /**
     * the query selector of the child container that should listen to the drag start event
     * if it is not defined the whole item is used
     *
     * @property dragStartContainerSelector
     * @type {String}
     * @default null
     */
    dragStartContainerSelector: null,
    /**
     * the current index of the item
     *
     * @property index
     * @type {Number}
     * @default null
     */
    index: null,
    /**
     * the container that listens to the drag start event
     *
     * @property _dragStartContainer
     * @type {HTMLElement}
     * @default null
     */
    _dragStartContainer: null,
    /**
     * whether the dragging is enabled
     *
     * @property _isDraggingEnabled
     * @type {Boolean}
     * @default true
     */
    _isDraggingEnabled: true,
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement() {
      this._super(...arguments);
      this._onDragStart = (0, _runloop.bind)(this, this._onDragStart);
      this._onDragEnd = (0, _runloop.bind)(this, this._onDragEnd);
      this._dragEnter = (0, _runloop.bind)(this, this._dragEnter);
      this._onDragStartContainerMouseDown = (0, _runloop.bind)(this, this._onDragStartContainerMouseDown);
      this._onDragStartContainerMouseUp = (0, _runloop.bind)(this, this._onDragStartContainerMouseUp);
      if (this.dragStartContainerSelector && this.element.querySelector(`${this.dragStartContainerSelector}`)) {
        this._dragStartContainer = this.element.querySelector(`${this.dragStartContainerSelector}`);
        this._isDraggingEnabled = false;
        this._dragStartContainer.addEventListener('mousedown', this._onDragStartContainerMouseDown, false);
        this._dragStartContainer.addEventListener('touchstart', this._onDragStartContainerMouseDown, false);
        this._dragStartContainer.addEventListener('mouseup', this._onDragStartContainerMouseUp, false);
        this._dragStartContainer.addEventListener('touchend', this._onDragStartContainerMouseUp, false);
      }
      if (this.element) {
        // drag events
        this.element.addEventListener('dragstart', this._onDragStart, false);
        this.element.addEventListener('dragend', this._onDragEnd, false);
        this.element.addEventListener('dragenter', this._dragEnter, false);
        // prevent browser from opening picture after dropping it
        this.element.addEventListener('drop', e => e.preventDefault(), false);
        // prevent browser from resetting current drag operation on dragover
        this.element.addEventListener('dragover', e => e.preventDefault(), false);
      }
    },
    //eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement() {
      this._super(...arguments);
      if (this.element) {
        this.element.removeEventListener('dragstart', this._onDragStart, false);
        this.element.removeEventListener('dragend', this._onDragEnd, false);
        this.element.removeEventListener('dragenter', this._dragEnter, false);
        this.element.removeEventListener('drop', e => e.preventDefault(), false);
        this.element.removeEventListener('dragover', e => e.preventDefault(), false);
      }
    },
    onChange() {},
    /**
     * allows the dragging of the item
     *
     * @function _onDragStartContainerMouseDown
     * @event mousedown|touchstart
     */
    _onDragStartContainerMouseDown() {
      this._isDraggingEnabled = true;
    },
    /**
     * disabled the dragging of the item
     *
     * @function _onDragStartContainerMouseUp
     * @event mouseup|touchend
     */
    _onDragStartContainerMouseUp() {
      this._isDraggingEnabled = false;
    },
    /**
     * sets the current element as dragged item
     *
     * @function _onDragStart
     * @event dragstart
     */
    _onDragStart(event) {
      if (!this._isDraggingEnabled) {
        event.preventDefault();
        return;
      }
      if (this.draggable) {
        this.element.style.opacity = 0.4;
        this.onChange('start', this.index);
      }
    },
    /**
     * sets the dragged item to null
     *
     * @function _onDragEnd
     * @event dragend
     */
    _onDragEnd() {
      if (this.draggable) {
        if (this._dragStartContainer) {
          this._isDraggingEnabled = false;
        }
        this.element.style.opacity = 1;
        this.onChange('end', this.index);
      }
    },
    /**
     * moves item to the position it is dragged over
     *
     * @function _dragEnter
     * @event dragenter
     */
    _dragEnter() {
      if (this.draggable) {
        this.onChange('dragover', this.index);
      }
    }
  });
});