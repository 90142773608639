define("@additive-apps/ui/helpers/ui-format-currency", ["exports", "@ember/component/helper", "@additive-apps/ui/utils/format-currency"], function (_exports, _helper, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.uiFormatCurrency = uiFormatCurrency;
  /**
   * Formats a currency value.
   *
   * @function uiFormatCurrency
   *
   * @param {number} value - The numeric value to format.
   * @param {string} [currency='EUR'] - The currency code (e.g., 'USD', 'EUR'). Sets the currency symbol of the output string.
   * @param {string} [locales='de-DE'] - The locale string (e.g., 'en-US', 'de-DE'). Sets the format  of the output string.
   * @param {number} [minimumFractionDigits=2] - The minimum number of fraction digits.
   * @param {number} [maximumFractionDigits=2] - The maximum number of fraction digits.
   * @param {number} [divider=100] - The value by which to divide the input value.
   *
   * @return {string} - The formatted currency string.
   */

  function uiFormatCurrency(params) {
    return (0, _formatCurrency.formatCurrency)({
      value: params[0],
      currency: params[1],
      locales: params[2],
      minimumFractionDigits: params[3],
      maximumFractionDigits: params[4],
      divider: params[5]
    });
  }
  var _default = _exports.default = (0, _helper.helper)(uiFormatCurrency);
});