define("@additive-apps/ui/utils/chart-tooltip-plugin", ["exports", "@ember/utils", "@additive-apps/ui/utils/format-currency"], function (_exports, _utils, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getChartTooltipPlugin = void 0;
  /**
   * Custom chart tooltip
   *
   * @function getChartTooltipPlugin
   * @param {String} locale - The locale to use for formatting the tooltip
   *
   * @return {Object} - The chart tooltip plugin
   */
  const getChartTooltipPlugin = locale => {
    const chartTooltipPlugin = {
      lastChartId: null,
      beforeInit: chart => {
        chart.options.plugins.tooltip = {
          enabled: false,
          mode: 'index',
          position: 'nearest',
          intersect: false,
          callbacks: {
            label: tooltipItem => {
              const dataset = tooltipItem.dataset;
              const datasetLabel = dataset.label || '';
              const data = tooltipItem.dataset.tooltipData || tooltipItem.dataset.data;
              const datasetData = {
                datasetLabel,
                itemLabel: tooltipItem.label,
                value: data[tooltipItem.dataIndex],
                format: dataset.format || 'number',
                currency: dataset.currency || 'EUR',
                color: dataset.tooltipColor || dataset.backgroundColor || dataset.pointBackgroundColor,
                isReference: dataset.isReference,
                invertColor: dataset.invertColor ? true : false,
                type: chart.config.type,
                stack: dataset.stack
              };

              // set comparison data
              if (dataset.compare) {
                const datasetComparison = {};
                datasetComparison.label = tooltipItem.chart.data.comparison.label;

                // get comparison data from dataset object if provided
                if (dataset.compare && dataset.compare.length > tooltipItem.dataIndex) {
                  const comparisonData = dataset.compare[tooltipItem.dataIndex];
                  if (typeof comparisonData === 'object') {
                    datasetComparison.value = comparisonData.value && comparisonData.value;
                    if (datasetComparison.value && typeof comparisonData.isPositive === 'boolean') {
                      datasetComparison.isPositive = comparisonData.isPositive;
                    }
                  } else {
                    datasetComparison.value = comparisonData;
                  }
                }
                datasetData.comparison = datasetComparison;
              }
              return datasetData;
            }
          },
          external: tooltipModel => {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.className = 'bg-white br1 pa1 shadow-2';
              tooltipEl.innerHTML = '<table class="table"></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.tooltip.opacity === 0) {
              chart.canvas.id === chartTooltipPlugin.lastChartId && (tooltipEl.style.opacity = 0);
              return;
            }
            chartTooltipPlugin.lastChartId = chart.canvas.id;

            // set content
            if (tooltipModel.tooltip.body) {
              const bodyLines = tooltipModel.tooltip.body.map(bodyItem => bodyItem.lines[0]);
              const normalLines = bodyLines.sort(line => !line.isReference);
              let headContent = [];
              let tableContent = [];

              // add tooltip title to first head cell
              headContent = [tooltipModel.tooltip.title[0]];

              // tooltip for stacks
              if (bodyLines[0].stack) {
                const stacks = {};
                bodyLines.forEach(line => {
                  if (line.stack) {
                    stacks[line.stack] = (stacks[line.stack] || []).concat([line]);
                  }
                });
                Object.keys(stacks).forEach((key, stackIndex) => {
                  if (stackIndex === 0) {
                    headContent = headContent.concat(stacks[key].map(line => line.datasetLabel));
                  }
                  tableContent.push([_createLabel(stacks[key][0], false, 'stack')]);
                  stacks[key].forEach(line => {
                    tableContent[stackIndex].push(_formatValue(line.value, line.format, line.currency, locale));
                  });
                });
              } else {
                // tooltip for other chart typess

                tableContent = normalLines.map(line => {
                  const isIndicatorRounded = line.type === 'line';
                  const content = [_createLabel(line, isIndicatorRounded), _formatValue(line.value, line.format, line.currency, locale)];
                  if (line.comparison) content.push(_createComparisonLabel(line, locale));
                  return content;
                });
                for (let i = headContent.length; i < tableContent[0].length; i++) {
                  headContent.push('');
                }
              }
              const head = _createTableHeader(headContent);
              const body = _createContent(tableContent);
              const tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = head;
              tableRoot.innerHTML += body;
            }
            const canvasBoundingRect = chart.canvas.getBoundingClientRect();
            const tooltipBoundingRect = tooltipEl.getBoundingClientRect();

            // get y-offset of highest positioned data point
            const offsetY = Math.min(...tooltipModel.tooltip.dataPoints.map(dataPoint => dataPoint.element.y));
            const tooltipPositionTop = canvasBoundingRect.top + window.pageYOffset + offsetY - tooltipBoundingRect.height - 8;
            let tooltipHalfWidth = Math.floor(tooltipEl.getBoundingClientRect().width / 2);
            let tooltipPositionLeft = canvasBoundingRect.left + window.pageXOffset + tooltipModel.tooltip.caretX - tooltipHalfWidth;

            // if tooltip is reaching outside of the screen on the left, correct the position
            tooltipPositionLeft < 8 && (tooltipPositionLeft = 8);

            // if tooltip is reaching outside of the screen on the right, correct the position
            const tooltipRightBorderPosition = tooltipPositionLeft + tooltipBoundingRect.width;
            const clientWidth = document.documentElement.clientWidth;
            tooltipRightBorderPosition >= clientWidth - 8 && (tooltipPositionLeft = clientWidth - 8 - tooltipBoundingRect.width);

            // display and position tooltip
            tooltipEl.style.opacity = 1;
            tooltipEl.style.left = `${tooltipPositionLeft}px`;
            tooltipEl.style.top = `${tooltipPositionTop}px`;
          }
        };
      }
    };
    return chartTooltipPlugin;
  };

  /**
   * creates an table head cell
   *
   * @function _createHeadCell
   * @param {String} content
   * @private
   */
  _exports.getChartTooltipPlugin = getChartTooltipPlugin;
  const _createHeadCell = content => {
    const headCell = document.createElement('th');
    headCell.className = 'table__head-cell ph1 font-sm secondary';
    headCell.innerHTML = content;
    return headCell.outerHTML;
  };

  /**
   * creates the table header given the content
   *
   * @function _createTableHeader
   * @param {Array}  content
   * @private
   * @return {String}
   */
  const _createTableHeader = content => {
    let htmlContent = '';
    content.forEach(element => {
      htmlContent += _createHeadCell(element);
    });
    const head = document.createElement('thead');
    head.className = 'table__head';
    const headRow = document.createElement('tr');
    headRow.className = 'table__head-row font-sm';
    headRow.innerHTML = htmlContent;
    head.innerHTML = headRow.outerHTML;
    return head.outerHTML;
  };

  /**
   * creates the table content given the rows
   *
   * @function _createContent
   * @param {Array}  rows
   * @private
   * @return {String}
   */
  const _createContent = rows => {
    const body = document.createElement('tbody');
    rows.forEach(row => {
      const tableRow = document.createElement('tr');
      tableRow.className = 'table__row font-sm';
      row.forEach((element, i) => {
        const cell = document.createElement('td');
        cell.className = `ph1 font-sm ${i === 0 ? 'text-left' : 'text-right'}`;
        cell.innerHTML = element;
        tableRow.innerHTML += cell.outerHTML;
      });
      body.innerHTML += tableRow.outerHTML;
    });
    return body.outerHTML;
  };

  /**
   * creates a label including the color indicator
   *
   * @function _createLabel
   * @param {Array} line
   * @param {Boolean} isRounded
   * @private
   * @return {String}
   */
  const _createLabel = function _createLabel(line) {
    let isRounded = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    let key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'datasetLabel';
    const div = document.createElement('div');
    div.className = 'flex items-center font-sm';
    div.style = `color: ${line.color};`;
    const span = document.createElement('span');
    span.className = 'mr1';
    span.style = `
    background-color: ${line.color};
    height: 8px;
    width: 8px;
    border-radius: ${isRounded ? '4px' : '0'}
  `;
    div.innerHTML = `${span.outerHTML} ${line[key]}`;
    return div.outerHTML;
  };

  /**
   * formats a given value with a format
   *
   * @function _formatValue
   * @param {Number|String} value
   * @param {String} format
   * @param currency
   * @param locale
   * @private
   * @return {String}
   */
  const _formatValue = function _formatValue(value, format, currency) {
    let locale = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'de';
    if ((0, _utils.isNone)(value)) {
      return '—';
    } else if (format === 'currency') {
      return (0, _formatCurrency.formatCurrency)({
        value,
        currency,
        locales: locale,
        divider: 1
      });
    } else if (format === 'percentage') {
      return (parseFloat(value || 0) / 100).toLocaleString(locale, {
        style: 'percent',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
    }
    return parseFloat(value).toLocaleString(locale);
  };

  /**
   * creates a comparison label with arrow
   *
   * @function _createComparisonLabel
   * @param {Object} line
   * @param locale
   * @private
   * @return {String}
   */
  const _createComparisonLabel = function _createComparisonLabel(line) {
    let locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'de';
    const {
      value
    } = line.comparison;
    const {
      invertColor
    } = line;
    const compareValue = Math.round(value * 100);
    let arrowClass, valueClass;
    if (invertColor) {
      arrowClass = compareValue > 0 ? 'arrow--negative arrow--increase' : 'arrow--positive';
      valueClass = `comparison-value${compareValue > 0 ? '--negative' : compareValue < 0 ? '--positive' : ''}`;
    } else {
      arrowClass = compareValue > 0 ? 'arrow--positive arrow--increase' : '';
      valueClass = `comparison-value${compareValue > 0 ? '--positive' : compareValue < 0 ? '--negative' : ''}`;
    }
    const formattedValue = Number(value * 100).toLocaleString(locale);
    const percentValue = `${formattedValue} %`;
    const div = document.createElement('div');
    div.className = 'flex items-center justify-end';
    if (compareValue !== 0) {
      const arrow = document.createElement('span');
      arrow.className = `arrow ${arrowClass}`;
      div.innerHTML = arrow.outerHTML;
    }
    const label = document.createElement('div');
    label.className = `comparison-value ${valueClass} font-sm pl05`;
    label.innerText = percentValue;
    div.innerHTML += label.outerHTML;
    return div.outerHTML;
  };
});