define("@additive-apps/ui/components/ui-tags-input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-concurrency", "ember-arg-types", "prop-types", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberConcurrency, _emberArgTypes, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes {{did-insert this.onInsert}} {{will-destroy this.onDestroy}}>
    {{#if this.description}}
      <div class="font-sm secondary">
        {{this.description}}
      </div>
    {{/if}}
    {{#unless this.isReadOnly}}
      <div class="flex items-center justify-center">
        <UiInput
          id="tagInput"
          @classNames="flex-grow-1 w-auto"
          @label={{this.inputLabel}}
          @value={{this._newTag}}
          @isReadOnly={{this.isReadOnly}}
          @isPlain={{this.isPlain}}
          @isTouched={{true}}
          @onChange={{this.resetError}}
          @errorMessage={{this._errorMessage}}
          {{did-insert this.inputSetup}}
        />
        <UiButton
          @icon="arrow-right"
          @isSecondary={{true}}
          @isLarge={{true}}
          @classNames={{if this.isPlain "ml1" "ml1 mt05"}}
          @onClick={{this.addTag}}
        />
      </div>
    {{/unless}}
    <div class="flex flex-wrap">
      {{#each (get this.changeset this.changesetProperty) as |tag|}}
        <div class="flex flex-column">
          <UiChip
            @classNames="flex mr1 mb1"
            @theme="grey"
            @isSelected={{not this.isReadOnly}}
            @isReadOnly={{this.isReadOnly}}
            @onDeselect={{fn this.removeTag tag}}
            @onBodyClick={{fn this.editTag tag}}
          >
            {{tag}}
          </UiChip>
        </div>
      {{/each}}
    </div>
  </div>
  
  */
  {
    "id": "1J7kQZ+N",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"onInsert\"]]],null],[4,[38,1],[[30,0,[\"onDestroy\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"description\"]],[[[1,\"    \"],[10,0],[14,0,\"font-sm secondary\"],[12],[1,\"\\n      \"],[1,[30,0,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[51,[30,0,[\"isReadOnly\"]]],[[[1,\"    \"],[10,0],[14,0,\"flex items-center justify-center\"],[12],[1,\"\\n      \"],[8,[39,4],[[24,1,\"tagInput\"],[4,[38,0],[[30,0,[\"inputSetup\"]]],null]],[[\"@classNames\",\"@label\",\"@value\",\"@isReadOnly\",\"@isPlain\",\"@isTouched\",\"@onChange\",\"@errorMessage\"],[\"flex-grow-1 w-auto\",[30,0,[\"inputLabel\"]],[30,0,[\"_newTag\"]],[30,0,[\"isReadOnly\"]],[30,0,[\"isPlain\"]],true,[30,0,[\"resetError\"]],[30,0,[\"_errorMessage\"]]]],null],[1,\"\\n      \"],[8,[39,5],null,[[\"@icon\",\"@isSecondary\",\"@isLarge\",\"@classNames\",\"@onClick\"],[\"arrow-right\",true,true,[52,[30,0,[\"isPlain\"]],\"ml1\",\"ml1 mt05\"],[30,0,[\"addTag\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"flex flex-wrap\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[28,[37,8],[[30,0,[\"changeset\"]],[30,0,[\"changesetProperty\"]]],null]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"flex flex-column\"],[12],[1,\"\\n        \"],[8,[39,9],null,[[\"@classNames\",\"@theme\",\"@isSelected\",\"@isReadOnly\",\"@onDeselect\",\"@onBodyClick\"],[\"flex mr1 mb1\",\"grey\",[28,[37,10],[[30,0,[\"isReadOnly\"]]],null],[30,0,[\"isReadOnly\"]],[28,[37,11],[[30,0,[\"removeTag\"]],[30,2]],null],[28,[37,11],[[30,0,[\"editTag\"]],[30,2]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,2]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"tag\"],false,[\"did-insert\",\"will-destroy\",\"if\",\"unless\",\"ui-input\",\"ui-button\",\"each\",\"-track-array\",\"get\",\"ui-chip\",\"not\",\"fn\"]]",
    "moduleName": "@additive-apps/ui/components/ui-tags-input.hbs",
    "isStrictMode": false
  });
  let UiTagsInputComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.string), _dec6 = (0, _emberArgTypes.arg)(_propTypes.object), _dec7 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec8 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec9 = (0, _emberArgTypes.arg)(_propTypes.func), _dec10 = (0, _emberArgTypes.arg)(_propTypes.func), _dec11 = (0, _emberArgTypes.arg)(_propTypes.func), _dec12 = (0, _emberConcurrency.task)(function* () {
    try {
      if (this._errorMessage) return;
      yield this.changeset.validate();
      if (!this.changeset['isValid']) {
        this._errorMessage = this.changeset.error[this.changesetProperty]['validation'][0];
        return;
      }
      yield this.changeset.save();
      yield this.onSave();
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }), _class = class UiTagsInputComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiDialog", _descriptor, this);
      _initializerDefineProperty(this, "uiToast", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      /**
       * the title of the component
       *
       * @argument title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor4, this);
      /**
       * the description of the component
       *
       * @argument description
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "description", _descriptor5, this);
      /**
       * the label displayed above the input
       *
       * @argument inputLabel
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "inputLabel", _descriptor6, this);
      /**
       * the label displayed on the save button
       *
       * @argument buttonLabel
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "buttonLabel", _descriptor7, this);
      /**
       * string that indicates which property of the changeset should the component use;
       * this property of the changeset has to be an array of strings
       *
       * @argument changesetProperty
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "changesetProperty", _descriptor8, this);
      /**
       * the changeset passed to the component
       *
       * @argument changeset
       * @type {Changeset}
       * @default null
       */
      _initializerDefineProperty(this, "changeset", _descriptor9, this);
      /**
       * whether the component is readOnly
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor10, this);
      /**
       * whether the label should be hidden
       *
       * @argument isPlain
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isPlain", _descriptor11, this);
      /**
       * the tag that gets edited by the input
       *
       * @property _newTag
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_newTag", _descriptor12, this);
      /**
       * the error message displayed on the input, as a string
       *
       * @property _errorMessage
       * @type {String}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_errorMessage", _descriptor13, this);
      _initializerDefineProperty(this, "onSave", _descriptor14, this);
      _initializerDefineProperty(this, "onInsert", _descriptor15, this);
      _initializerDefineProperty(this, "onDestroy", _descriptor16, this);
      _initializerDefineProperty(this, "_save", _descriptor17, this);
    }
    inputSetup() {
      // adds eventListener that triggers addTag function on enter button press
      document.getElementById('tagInput').addEventListener('keydown', e => {
        if (e.code === 'Enter') {
          this.addTag();
        }
      });

      // adds eventListener that triggers resetError function on paste
      document.getElementById('tagInput').addEventListener('paste', () => {
        this.resetError();
      });
    }
    resetError() {
      this._errorMessage = null;
    }
    addTag() {
      if (!this._newTag) return;
      const savedTags = [...(this.changeset.get(this.changesetProperty) || [])];
      if (savedTags !== null && savedTags !== void 0 && savedTags.includes(this._newTag)) {
        this._newTag = '';
        return;
      }
      const updatedTags = [...(this.changeset.get(this.changesetProperty) || [])];
      updatedTags.push(this._newTag);
      this.changeset.set(this.changesetProperty, updatedTags);
      if (!this.changeset['isValid']) {
        this._errorMessage = this.changeset.error[this.changesetProperty]['validation'][0];
        this.changeset.set(this.changesetProperty, savedTags);
        return;
      }
      this._newTag = '';
      this._save.perform();
    }
    removeTag(tag) {
      var _this$changeset;
      const savedTags = [...this.changeset.get(this.changesetProperty)];
      (savedTags === null || savedTags === void 0 ? void 0 : savedTags.indexOf(tag)) > -1 && savedTags.splice(savedTags.indexOf(tag), 1);
      (_this$changeset = this.changeset) === null || _this$changeset === void 0 || _this$changeset.set(this.changesetProperty, savedTags);
      this._save.perform();
    }
    editTag(tag) {
      this._newTag = tag;
      document.getElementById('tagInput').focus();
      this.removeTag(tag);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "inputLabel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "buttonLabel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "changesetProperty", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isPlain", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_newTag", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_errorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "onInsert", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "onDestroy", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "_save", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "inputSetup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "inputSetup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editTag", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editTag"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiTagsInputComponent);
});