define("@additive-apps/ui/components/ui-notification", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "ember-arg-types", "prop-types", "@additive-apps/ui/utils/dom-util", "@additive-apps/ui/utils/local-storage"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _runloop, _emberArgTypes, _propTypes, _domUtil, _localStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-notification flex items-center justify-center ph2-sm pb2-sm white w-100
      {{if this.isWarning 'ui-notification--warning'}}
      {{if this.isInfo 'ui-notification--info'}}
      {{if this.isDanger 'ui-notification--danger'}}
      {{if this._isDismissed 'ui-notification--dismissed'}}
      {{if this._isEntering 'ui-notification--entering'}}
      {{if this._isDestroyed 'dn'}}
      "
    {{did-insert this.onDidInsert}}
  >
    <div
      class="ui-notification__inner flex w-100 pv05 ph1 br1-lg shadow-3-lg
        {{unless this.icon 'pl05'}}"
    >
      {{#if this.icon}}
        <div class="ui-notification__icon flex items-center justify-center pl05 pr1 pv1">
          {{svg-jar this.icon class="white"}}
        </div>
      {{/if}}
  
      <div
        class="ui-notification__title flex flex-column justify-center flex-grow-1 pv1 font-sm
          {{unless this.icon 'ph1'}}"
      >
        {{this.title}}
      </div>
      {{yield}}
      <UiIconButton
        @class="color-inherit"
        @icon="close"
        @onClick={{this._onClose}}
        data-test-notification-close-button
      />
    </div>
  </div>
  */
  {
    "id": "tTIWhdCM",
    "block": "[[[11,0],[16,0,[29,[\"ui-notification flex items-center justify-center ph2-sm pb2-sm white w-100\\n    \",[52,[30,0,[\"isWarning\"]],\"ui-notification--warning\"],\"\\n    \",[52,[30,0,[\"isInfo\"]],\"ui-notification--info\"],\"\\n    \",[52,[30,0,[\"isDanger\"]],\"ui-notification--danger\"],\"\\n    \",[52,[30,0,[\"_isDismissed\"]],\"ui-notification--dismissed\"],\"\\n    \",[52,[30,0,[\"_isEntering\"]],\"ui-notification--entering\"],\"\\n    \",[52,[30,0,[\"_isDestroyed\"]],\"dn\"],\"\\n    \"]]],[4,[38,1],[[30,0,[\"onDidInsert\"]]],null],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"ui-notification__inner flex w-100 pv05 ph1 br1-lg shadow-3-lg\\n      \",[52,[51,[30,0,[\"icon\"]]],\"pl05\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"icon\"]],[[[1,\"      \"],[10,0],[14,0,\"ui-notification__icon flex items-center justify-center pl05 pr1 pv1\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,0,[\"icon\"]]],[[\"class\"],[\"white\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[15,0,[29,[\"ui-notification__title flex flex-column justify-center flex-grow-1 pv1 font-sm\\n        \",[52,[51,[30,0,[\"icon\"]]],\"ph1\"]]]],[12],[1,\"\\n      \"],[1,[30,0,[\"title\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[18,1,null],[1,\"\\n    \"],[8,[39,5],[[24,\"data-test-notification-close-button\",\"\"]],[[\"@class\",\"@icon\",\"@onClick\"],[\"color-inherit\",\"close\",[30,0,[\"_onClose\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"if\",\"did-insert\",\"unless\",\"svg-jar\",\"yield\",\"ui-icon-button\"]]",
    "moduleName": "@additive-apps/ui/components/ui-notification.hbs",
    "isStrictMode": false
  });
  const uiStorageKey = 'ui-notifications';

  /**
   *  Floating notification component
   *
   * ```hbs
   * {{ui-notification
   *   title="Test"
   *   icon="info"
   *   isWarning=false
   *   isInfo=false
   *   isDanger=true
   *   key="key"
   *   maxDisplayTimes
   *   parent
   * }}
   * ```
   *
   * @class ui-notification
   */
  let UiNotification = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.number), _dec6 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec7 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec8 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec9 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec10 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiNotification extends _component2.default {
    /*
     * Checks how often the notification has been displayed: If it has never been shown it
     * initializes a value in the local storage to 1 (displayed 1 time).
     * The value gets incremented everytime the notification is shown. When it reaches its
     * maximal display count it is not shown again.
     */
    constructor() {
      super(...arguments);
      /**
       * Title of notification
       *
       * @property title
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "title", _descriptor, this);
      /**
       * Icon to display
       *
       * @property icon
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "icon", _descriptor2, this);
      /**
       * Container of notifiaction
       *
       * @property parent
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "parent", _descriptor3, this);
      /**
       * Key value to be saved in local storage
       *
       * @property key
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "key", _descriptor4, this);
      /**
       * Amount of times to notification will be displayed
       *
       * @property maxDisplayTimes
       * @type {Integer}
       * @default null
       */
      _initializerDefineProperty(this, "maxDisplayTimes", _descriptor5, this);
      /**
       * Whether it is a warning notification (yellow)
       *
       * CSS-class:
       *  - `ui-notification--warning
       *
       * @property isWarning
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isWarning", _descriptor6, this);
      /**
       * Whether it is an info notification (blue)
       *
       * CSS-class:
       *  - `ui-notification--info
       *
       * @property isInfo
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isInfo", _descriptor7, this);
      /**
       * Whether it is an danger notification (red)
       *
       * CSS-class:
       *  - `ui-notification--danger
       *
       * @property isInfo
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isDanger", _descriptor8, this);
      /**
       * Whether it is a Floating notification: displaying over the whole screen,
       * only on desktop.
       *
       * CSS-class:
       *  - `ui-notification--floating
       *
       * @property isFloating
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isFloating", _descriptor9, this);
      /**
       * Whether the notification has been closed
       *
       * @property _isDestroyed
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_isDestroyed", _descriptor10, this);
      /**
       * Whether it has already been closed
       *
       * CSS-class:
       *  - `ui-notification--dismissed
       *
       * @property _isDismissed
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_isDismissed", _descriptor11, this);
      /**
       * Whether the notification is entering
       *
       * @property _isEntering
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "_isEntering", _descriptor12, this);
      _initializerDefineProperty(this, "onClose", _descriptor13, this);
      const key = this.key;
      if ((0, _localStorage.supportsLocalStorage)() && key && this.maxDisplayTimes) {
        if (localStorage.getItem(uiStorageKey)) {
          let storedItem = JSON.parse(localStorage.getItem(uiStorageKey));
          const timesDisplayed = storedItem[key] || 0;
          if (timesDisplayed >= this.maxDisplayTimes) {
            this._isDismissed = true;

            //workaround to destroy it as soon as it is rendered
            this._isDestroyed = true;
          } else {
            storedItem[key] = timesDisplayed + 1;
            localStorage.setItem(uiStorageKey, JSON.stringify(storedItem));
          }
        } else {
          localStorage.setItem(uiStorageKey, `{ "${key}": 1 }`);
        }
      }
    }
    destroyNotification() {
      this._isDestroyed = true;
    }
    async onDidInsert() {
      //destroy immeadiately if it shouldn't be shown
      if (!this._isDestroyed) {
        await (0, _domUtil.nextTick)();
        !this.isDestroying && (this._isEntering = true);
      }
    }
    async _onClose() {
      await (0, _domUtil.nextTick)();
      this._isDismissed = true;
      (0, _runloop.later)(this, 'destroyNotification', 400);
      (0, _runloop.later)(this, 'onClose', 400);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "icon", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "parent", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "key", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "maxDisplayTimes", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isWarning", [_dec6, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isInfo", [_dec7, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isDanger", [_dec8, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isFloating", [_dec9, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_isDestroyed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_isDismissed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_isEntering", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onClose", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_onClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_onClose"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiNotification);
});