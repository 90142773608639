define("@additive-apps/auth/adapters/application", ["exports", "@ember-data/adapter/rest", "@ember/service", "@ember-data/adapter", "@ember-data/adapter/error"], function (_exports, _rest, _service, _adapter, _error) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
   * To use this adapter in an application it has to be
   * extended defining the host.
   * The current Organization needs to be set in the currentUser service.
   */
  let ApplicationAdapter = _exports.default = (_class = class ApplicationAdapter extends _rest.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "session", _descriptor4, this);
      _defineProperty(this, "fallBackRoute", 'instance');
    }
    get namespace() {
      var _this$currentUser;
      const currentOrganizationId = (_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.currentOrganization) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.id;
      if (currentOrganizationId) {
        return `api/${currentOrganizationId}`;
      } else {
        return 'api';
      }
    }

    /**
     * The authorization header is added manually since the authorize method
     * used with ember-ajax is not called with ember-fetch.
     */
    get headers() {
      const headers = {};
      if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
      }
      return headers;
    }
    handleResponse(status, _, payload) {
      const router = this.router;

      /*
       * We treat a general 400 error as generic adapter error.
       * The errors payload has to be wellformed, i.e.
       * the unique error-key has to be de message property
       */
      if (status === 400) {
        return new _adapter.default([{
          status: status,
          code: payload.code,
          detail: payload.message
        }]);
      } else if (status === 401) {
        // invalidate session if request is unauthorized
        this.session.invalidate();
        return;
      } else if (status === 403) {
        // show dialog if user has no permissions
        this.uiDialog.showMissingPermissions();
        return;
      } else if (status === 404) {
        // transition to "not found" route when api returns 404
        router.transitionTo('/not-found');
        return;
      } else if (status !== 422 && status > 400) {
        router.transitionTo(this.fallBackRoute).then(() => this.uiDialog.showError());
        return;
      }
      if (this.isInvalid(...arguments)) {
        payload.errors = (0, _error.errorsHashToArray)(payload.errors);
      }
      return super.handleResponse(...arguments);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});