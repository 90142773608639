define("@additive-apps/ui/components/ui-switch", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "ember-arg-types", "prop-types"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _emberArgTypes, _propTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-switch {{unless this.label 'ui-switch__simple'}} {{this._classNames}}"
    role="button"
    tabindex="0"
    aria-label="switch"
    ...attributes
    {{on "click" this.click}}
  >
    <div class="ui-switch__container">
      {{#if (or this._isLoading this.isLoading)}}
        <UiLoading />
      {{else}}
        <div class="ui-switch__bar"></div>
        <div class="ui-switch__thumb-container">
          <div class="ui-switch__thumb"></div>
        </div>
      {{/if}}
    </div>
    {{#if this.label}}
      <div
        class={{concat
          "font-sm secondary pl3 ml2 "
          (if this.value "blue" "secondary")
          (unless this.isReadOnly " cpointer")
        }}
      >
        {{this.label}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "BPPGKLnT",
    "block": "[[[11,0],[16,0,[29,[\"ui-switch \",[52,[51,[30,0,[\"label\"]]],\"ui-switch__simple\"],\" \",[30,0,[\"_classNames\"]]]]],[24,\"role\",\"button\"],[24,\"tabindex\",\"0\"],[24,\"aria-label\",\"switch\"],[17,1],[4,[38,1],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui-switch__container\"],[12],[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"_isLoading\"]],[30,0,[\"isLoading\"]]],null],[[[1,\"      \"],[8,[39,4],null,null,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"ui-switch__bar\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"ui-switch__thumb-container\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"ui-switch__thumb\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"    \"],[10,0],[15,0,[28,[37,5],[\"font-sm secondary pl3 ml2 \",[52,[30,0,[\"value\"]],\"blue\",\"secondary\"],[52,[51,[30,0,[\"isReadOnly\"]]],\" cpointer\"]],null]],[12],[1,\"\\n      \"],[1,[30,0,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\"],false,[\"unless\",\"on\",\"if\",\"or\",\"ui-loading\",\"concat\"]]",
    "moduleName": "@additive-apps/ui/components/ui-switch.hbs",
    "isStrictMode": false
  });
  /**
   * Component that shows the current state of a boolean and handle the toggle of the value
   *
   * TODO:
   * Add tabindex, keyevents and disabled
   * - Integration test
   *
   * @class UiSwitch
   */
  let UiSwitch = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec3 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec4 = (0, _emberArgTypes.arg)(_propTypes.string), _dec5 = (0, _emberArgTypes.arg)(_propTypes.boolean), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class UiSwitch extends _component2.default {
    constructor() {
      super(...arguments);
      /**
       * the class names of the component
       *
       * @argument class
       * @type {String}
       * @default ''
       */
      _initializerDefineProperty(this, "class", _descriptor, this);
      /**
       * whether the component is read only
       *
       * @argument isReadOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isReadOnly", _descriptor2, this);
      /**
       * the value
       *
       * @argument value
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "value", _descriptor3, this);
      /**
       * the label
       *
       * @argument label
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "label", _descriptor4, this);
      /**
       * whether the component is in loading state
       *
       * @argument isLoading
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isLoading", _descriptor5, this);
      /**
       * whether the component is in loading state
       *
       * @property _isLoading
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isLoading", _descriptor6, this);
      /**
       * the function triggered on click
       *
       * @function onChange
       */
      _initializerDefineProperty(this, "onChange", _descriptor7, this);
    }
    get _classNames() {
      let classNames = this.class;
      if (this.value) {
        classNames += ' ui-switch--checked';
      }
      if (this.isReadOnly) {
        classNames += ' ui-switch--readonly';
      }
      if (this.label) {
        classNames += ' ui-switch__label';
      }
      return classNames;
    }
    async click() {
      if (this.isReadOnly || this.isLoading) {
        return;
      }
      this._isLoading = true;
      await this.onChange(!this.value);
      this._isLoading = false;
      return this.bubbles;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "class", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isReadOnly", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "label", [_dec4, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function initializer() {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiSwitch);
});