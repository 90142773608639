define("@additive-apps/ui/components/ui-table/row", ["exports", "@ember/component", "@additive-apps/ui/templates/components/ui-table/row", "@ember/array", "@additive-apps/ui/utils/function-util", "@ember/object"], function (_exports, _component, _row, _array, _functionUtil, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //eslint-disable-next-line ember/no-classic-components
  //eslint-disable-next-line ember/no-classic-classes
  var _default = _exports.default = _component.default.extend({
    layout: _row.default,
    //eslint-disable-next-line ember/require-tagless-components
    tagName: 'tr',
    classNames: ['ui-table__row'],
    classNameBindings: ['isSelected:ui-table__row--selected', '_hasClickEvent:cpointer', 'isActive:ui-table__row--active'],
    /**
     *  whether the row is active
     *
     *  @property isActive
     *  @type {Boolean}
     *  @default false
     */
    isActive: false,
    /**
     * the columns array
     * ```js
     *  [{ name: String, key: String, minWidth: String, classNames: String, onClick: Function }]
     * ```
     * @property columns
     * @type {Object[]}
     * @default undefined
     */
    columns: undefined,
    /**
     * whether there are selected rows in the table
     *
     * @property hasSelectedItems
     * @type {Boolean}
     * @default false
     */
    hasSelectedItems: false,
    /**
     * whether the row should be selectable i.e. a checkbox should be rendered
     *
     * @property isSelectable
     * @type {Boolean}
     * @default false
     */
    isSelectable: false,
    /**
     * whether the actions cell should be displayed or not
     *
     * @property hasActions
     * @type {Boolean}
     * @default false
     */
    hasActions: true,
    /**
     * the row object containing the values
     *
     * @property row
     * @type {Object}
     * @default undefined
     */
    row: undefined,
    /**
     * the actions for the menu:
     *
     * {name: String, action: Function}
     *
     * @property menuActions
     * @type {Object[]}
     * @default undefined
     */
    menuActions: undefined,
    /**
     * whether the row is in array form
     *
     * @computed isArray
     * @type {Boolean}
     */
    isArray: (0, _object.computed)('row', {
      get() {
        return (0, _array.isArray)(this.row);
      }
    }),
    /**
     * Whether the row has an click event
     *
     * @property _hasClickEvent
     * @type {Boolean}
     * @private
     */
    _hasClickEvent: (0, _object.computed)('onClick', {
      get() {
        return (0, _functionUtil.hasBody)(this.onClick);
      }
    }),
    /**
     * handles the click event on the table row
     *
     * @param {Event} event
     * @function click
     */
    click() {
      if (this._hasClickEvent) {
        this._onClick();
      }
    },
    /**
     * callback triggered when a row is selected
     *
     * @function onSelect
     * @param {Object} row
     * @param {Boolean} isSelected
     */
    onSelect() {},
    /**
     * callback triggered on click, can be overwritten
     *
     * @function onClick
     * @event mouseup
     */
    onClick() {},
    /**
     * triggers select callback
     *
     * @function select
     */
    selectItem() {
      if (this.isSelectable) {
        const isSelected = !this.isSelected;
        const row = this.row;
        this.onSelect(row, isSelected);
      }
    },
    /**
     * click function, triggers the onClick callback if no items are selected, or
     * selects/deselects if the table contains selected rows
     *
     * @function onClick
     */
    _onClick() {
      const hasSelectedItems = this.hasSelectedItems;
      const isSelectable = this.isSelectable;

      // if table has selected items a click should select/unselect the item
      if (hasSelectedItems && isSelectable) {
        this.selectItem();
      } else {
        this.onClick(this.row);
      }
    },
    //eslint-disable-next-line ember/no-actions-hash
    actions: {
      select() {
        this.selectItem();
      }
    }
  });
});